import { default as S } from './MainContent34.styled';
import { values } from '@/features/main/configs';

interface TProps {
  bgColor?: string;
}

export default function MainContent34({ bgColor }: TProps) {
  return (
    <S.Section id="main-content-34" bgColor={bgColor}>
      <S.Background>
        <img
          loading="lazy"
          src={`${values.cdnPrefix}/images/content34/1.png`}
          alt="배경"
        />
      </S.Background>
      <S.Content>
        <img
          loading="lazy"
          src={`${values.cdnPrefix}/images/content34/2.png`}
          alt="배경"
        />
      </S.Content>
    </S.Section>
  );
}
