import styled from '@emotion/styled';

const Section = styled.section<{ bgColor?: string }>`
  position: relative;
  min-height: 300px;
  height: auto;
  overflow: hidden;
  background-color: ${(props) => props.bgColor || '#FFFFFF'};

  button {
    position: absolute;
    top: 85.5%;
    left: 50%;
    height: 2.5%;
    cursor: pointer;

    img {
      height: 100%;
    }
  }
`;

const Background = styled.div`
  display: flex;
  justify-content: center;
  img {
    max-width: 1260px;
    width: 100%;
  }
`;

const StyledMainContent8 = { Section, Background };

export default StyledMainContent8;
