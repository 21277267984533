'use client';

import React, { useEffect, useState } from 'react';
import { default as S } from './MainContent.styled';
import {
  MainContent0,
  MainContent1,
  MainContent13,
  MainContent15,
  MainContent17,
  MainContent2,
  MainContent23,
  MainContent24,
  MainContent26,
  MainContent28,
  MainContent3,
  MainContent30,
  MainContent32,
  MainContent34,
  MainContent35,
  MainContent4,
  MainContent6,
  MainContent8,
  SimpleMainContent,
} from '../components';
import values from '../configs/values';

export default function MainContent() {
  const [colorConfig, setColorConfig] = useState<{
    mainContent: { [key: string]: any };
  } | null>(null);

  useEffect(() => {
    const isProduction = process.env.NEXT_PUBLIC_SERVICE_ENV === 'production';
    const url = `${values.cdnPrefix}/main_content_colors${
      isProduction ? '' : '_staging'
    }.json`;
    fetch(url)
      .then((res) => res.json())
      .then((json) => setColorConfig(json));
  }, []);

  const mainContentColor = colorConfig?.mainContent || {};

  return (
    <S.Main>
      <MainContent0 bgColor={mainContentColor['0']} />
      <MainContent1 bgColor={mainContentColor['1']} />
      <MainContent2 bgColor={mainContentColor['2']} />
      <MainContent3 bgColor={mainContentColor['3']} />
      <MainContent4 bgColor={mainContentColor['4']} />
      <SimpleMainContent
        id="main-content-5"
        bgColor={mainContentColor['5']}
        src={`${values.cdnPrefix}/images/content5/1.png`}
        alt="교육 전문가, 현직 초등교사, 학부모까지 모두가 인정하는 검증된 학습법 윙크"
      />
      <MainContent6 bgColor={mainContentColor['6']} />
      <SimpleMainContent
        id="main-content-7"
        bgColor={mainContentColor['7']}
        src={`${values.cdnPrefix}/images/content7/1.png`}
        alt="유아부터 고등까지 1위 스마트학습을 선도해온 전문가 그룹 단비교육 라인업"
      />
      <MainContent8 bgColor={mainContentColor['8']} />
      {[
        {
          id: 'main-content-9',
          bgColor: mainContentColor['9'],
          imageName: 'content9/1.png',
          alt: '우리 아이 처음 학습은 표준을 선도하는 1위 학습 윙크로 시작해 보세요',
        },
        {
          id: 'main-content-10',
          bgColor: mainContentColor['10'],
          imageName: 'content10/1.png',
          alt: '시중학습과 윙크 비교',
        },
        {
          id: 'main-content-11',
          bgColor: mainContentColor['11'],
          imageName: 'content11/1.png',
          alt: '1위만의 학습 자신감! 시중 학습에는 없는 윙크만의 체크 포인트를 꼭 확인해 보세요.',
        },
        {
          id: 'main-content-12',
          bgColor: mainContentColor['12'],
          imageName: 'content12/1.png',
          alt: '종이 학습지 최다 제공',
        },
      ].map(({ id, bgColor, imageName, alt }) => (
        <SimpleMainContent
          key={id}
          id={id}
          bgColor={bgColor}
          src={`${values.cdnPrefix}/images/${imageName}`}
          alt={alt}
        />
      ))}
      <MainContent13 bgColor={mainContentColor['13']} />
      <SimpleMainContent
        id="main-content-14"
        bgColor={mainContentColor['14']}
        src={`${values.cdnPrefix}/images/content14/1.png`}
        alt="학습지를 풀다가 모르는 문제가 나오면?"
      />
      <MainContent15 bgColor={mainContentColor['15']} />
      <SimpleMainContent
        id="main-content-16"
        bgColor={mainContentColor['16']}
        src={`${values.cdnPrefix}/images/content16/1.png`}
        alt="한수영 모두 충분한 학습량과 코스웨어"
      />
      <MainContent17 bgColor={mainContentColor['17']} />
      {[
        {
          id: 'main-content-18',
          bgColor: mainContentColor['18'],
          imageName: 'content18/1.png',
          alt: '초등 교과까지 동시 학습',
        },
        {
          id: 'main-content-19',
          bgColor: mainContentColor['19'],
          imageName: 'content19/1.png',
          alt: '예비초 학부모님들의 걱정을 모두 날려주는 윙크만의 초등입학 대비학습 프로그램',
        },
        {
          id: 'main-content-20',
          bgColor: mainContentColor['20'],
          imageName: 'content20/1.png',
          alt: '스마트학습 중 유일 실물도서 120권으로 1:1 독서수업까지 제공',
        },
        {
          id: 'main-content-21',
          bgColor: mainContentColor['21'],
          imageName: 'content21/1.png',
          alt: '독서는 한글,국어,수학 등 모든 학습 능력의 기초',
        },
        {
          id: 'main-content-22',
          bgColor: mainContentColor['22'],
          imageName: 'content22/1.png',
          alt: '유아동 맞춤 특허학습기로 공부하는 유일한 학습',
        },
      ].map(({ id, bgColor, imageName, alt }) => (
        <SimpleMainContent
          key={id}
          id={id}
          bgColor={bgColor}
          src={`${values.cdnPrefix}/images/${imageName}`}
          alt={alt}
        />
      ))}
      <MainContent23 bgColor={mainContentColor['23']} />
      <MainContent24 bgColor={mainContentColor['24']} />
      <SimpleMainContent
        id="main-content-25"
        bgColor={mainContentColor['25']}
        src={`${values.cdnPrefix}/images/content25/1.png`}
        alt="초등 교과까지 동시 학습"
      />
      <MainContent26 bgColor={mainContentColor['26']} />
      <SimpleMainContent
        id="main-content-27"
        bgColor={mainContentColor['27']}
        src={`${values.cdnPrefix}/images/content27/1.png`}
        alt="충분한 분량과 완벽한 코스의 3단계 쓰기 학습 제공"
      />
      <MainContent28 bgColor={mainContentColor['28']} />
      <SimpleMainContent
        id="main-content-29"
        bgColor={mainContentColor['29']}
        src={`${values.cdnPrefix}/images/content29/1.png`}
        alt="누리 과정, 부가 학습 콘텐츠도 6,000편 이상 최다"
      />
      <MainContent30 bgColor={mainContentColor['30']} />
      <SimpleMainContent
        id="main-content-31"
        bgColor={mainContentColor['31']}
        src={`${values.cdnPrefix}/images/content31/1.png`}
        alt="매월 집에서 받아보는 실물의 학습 칭찬 선물"
      />
      <MainContent32 bgColor={mainContentColor['32']} />
      <SimpleMainContent
        id="main-content-33"
        bgColor={mainContentColor['33']}
        src={`${values.cdnPrefix}/images/content33/1.png`}
        alt="원클릭 자녀학습 맞춤 설정 학부모 앱"
      />
      <MainContent34 bgColor={mainContentColor['34']} />
      <MainContent35 bgColor={mainContentColor['35']} />
      <SimpleMainContent
        id="main-content-36"
        bgColor={mainContentColor['36']}
        src={`${values.cdnPrefix}/images/content36/1.png`}
        alt="꼭 확인해 주세요"
      />
    </S.Main>
  );
}
