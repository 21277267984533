import { useRouter } from 'next/router';
import { values } from '@/features/main/configs';
import { default as S } from './MainContent8.styled';

interface TProps {
  bgColor?: string;
}

export default function MainContent8({ bgColor }: TProps) {
  const router = useRouter();
  return (
    <S.Section id="main-content-8" bgColor={bgColor}>
      <S.Background>
        <img
          loading="lazy"
          src={`${values.cdnPrefix}/images/content8/1.png`}
          alt="유아학습 '윙크' 와 초중등 학습 '캐츠홈'"
        />
      </S.Background>
      <button
        onClick={() =>
          router.push(
            'https://promotion.cats4me.net/parents/campaign?code=23B001&utm_source=winkweb&utm_medium=PC&utm_content=&utm_campaign='
          )
        }
      >
        <img
          loading="lazy"
          src={`${values.cdnPrefix}/images/content8/2.png`}
          alt="자세히보기"
        />
      </button>
    </S.Section>
  );
}
