import styled from '@emotion/styled';

const Section = styled.section<{ bgColor?: string }>`
  position: relative;
  min-height: 300px;
  height: auto;
  overflow: hidden;
  background-color: ${(props) => props.bgColor || '#FFFFFF'};
`;

const Background = styled.div`
  display: flex;
  justify-content: center;
`;

const Background2 = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  img {
    max-width: 1260px;
    width: 100%;
  }
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const ImgGroup = styled.div`
  position: relative;
  max-width: 1260px;
  width: 100%;
  height: 100%;
`;

const GifWrapper = styled.div`
  position: absolute;
  width: 25.5%;
  height: 12%;
  background-color: #ffffff;
  border-radius: 1rem;
  overflow: hidden;
  padding: 0.3rem;
  box-sizing: border-box;

  img {
    border-radius: 0.75rem;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
`;

const GifWrapper1 = styled(GifWrapper)`
  top: 50%;
  left: 24%;
`;

const GifWrapper2 = styled(GifWrapper)`
  top: 50%;
  right: 24%;
`;

const GifWrapper3 = styled(GifWrapper)`
  top: 70.7%;
  left: 24%;
`;

const GifWrapper4 = styled(GifWrapper)`
  top: 70.7%;
  right: 24%;
`;

const StyledMainContent30 = {
  Section,
  Background,
  Content,
  Background2,
  ImgGroup,
  GifWrapper1,
  GifWrapper2,
  GifWrapper3,
  GifWrapper4,
};

export default StyledMainContent30;
