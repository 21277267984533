import styled from '@emotion/styled';

const Section = styled.section<{ bgColor?: string }>`
  position: relative;
  min-height: 300px;
  height: auto;
  overflow: hidden;
  background-color: ${(props) => props.bgColor || '#FFFFFF'};
`;

const Background = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  img {
    max-width: 1260px;
    width: 100%;
  }
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const ImgGroup = styled.div`
  position: relative;
  max-width: 1260px;
  width: 100%;
  height: 100%;
`;

const GifWrapper = styled.div`
  position: absolute;
  height: 30.5%;
  width: 22.5%;
  top: 40.9%;
  border-radius: 1rem;
  overflow: hidden;
  img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const GifWrapper1 = styled(GifWrapper)`
  left: 14%;
`;
const GifWrapper2 = styled(GifWrapper)`
  left: 38.9%;
`;
const GifWrapper3 = styled(GifWrapper)`
  left: 63.7%;
`;

const StyledMainContent28 = {
  Section,
  Background,
  Content,
  ImgGroup,
  GifWrapper1,
  GifWrapper2,
  GifWrapper3,
};

export default StyledMainContent28;
