import { default as S } from './MainContent30.styled';
import { values } from '@/features/main/configs';

interface TProps {
  bgColor?: string;
}

export default function MainContent30({ bgColor }: TProps) {
  return (
    <S.Section id="main-content-30" bgColor={bgColor}>
      <S.Background>
        <img
          loading="lazy"
          src={`${values.cdnPrefix}/images/content30/1.png`}
          alt=""
        />
      </S.Background>
      <S.Background2>
        <img
          loading="lazy"
          src={`${values.cdnPrefix}/images/content30/2.png`}
          alt=""
        />
      </S.Background2>
      <S.Content>
        <S.ImgGroup>
          <S.GifWrapper1>
            <img
              loading="lazy"
              src={`${values.cdnPrefix}/images/content30/3_1.gif`}
              alt=""
            />
          </S.GifWrapper1>
          <S.GifWrapper2>
            <img
              loading="lazy"
              src={`${values.cdnPrefix}/images/content30/3_2.gif`}
              alt=""
            />
          </S.GifWrapper2>
          <S.GifWrapper3>
            <img
              loading="lazy"
              src={`${values.cdnPrefix}/images/content30/3_3.gif`}
              alt=""
            />
          </S.GifWrapper3>
          <S.GifWrapper4>
            <img
              loading="lazy"
              src={`${values.cdnPrefix}/images/content30/3_4.gif`}
              alt=""
            />
          </S.GifWrapper4>
        </S.ImgGroup>
      </S.Content>
    </S.Section>
  );
}
