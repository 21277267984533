import { default as S } from './SimpleMainContent.styled';

interface TProps {
  id: string;
  bgColor?: string;
  src: string;
  alt: string;
}

export default function SimpleMainContent({ id, bgColor, src, alt }: TProps) {
  return (
    <S.Section id={id} bgColor={bgColor}>
      <img loading="lazy" src={src} alt={alt} />
    </S.Section>
  );
}
