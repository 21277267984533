import styled from '@emotion/styled';

const Section = styled.section<{ bgColor?: string }>`
  position: relative;
  width: 100%;
  min-height: 300px;
  height: auto;
  display: flex;
  justify-content: center;
  overflow: hidden;
  background-color: ${(props) => props.bgColor || '#FFFFFF'};
`;

const Background = styled.img`
  max-width: 1260px;
  width: 100%;
`;

const Gif = styled.img`
  position: absolute;
  top: 28%;
  height: 35%;
`;

const MainContent13 = {
  Background,
  Section,
  Gif,
};

export default MainContent13;
