import { default as S } from './MainContent28.styled';
import { values } from '@/features/main/configs';

interface TProps {
  bgColor?: string;
}

export default function MainContent28({ bgColor }: TProps) {
  return (
    <S.Section id="main-content-28" bgColor={bgColor}>
      <S.Background>
        <img
          loading="lazy"
          src={`${values.cdnPrefix}/images/content28/1.png`}
          alt=""
        />
      </S.Background>
      <S.Content>
        <S.ImgGroup>
          <S.GifWrapper1>
            <img
              loading="lazy"
              src={`${values.cdnPrefix}/images/content28/2_1.gif`}
              alt=""
            />
          </S.GifWrapper1>
          <S.GifWrapper2>
            <img
              loading="lazy"
              src={`${values.cdnPrefix}/images/content28/2_2.gif`}
              alt=""
            />
          </S.GifWrapper2>
          <S.GifWrapper3>
            <img
              loading="lazy"
              src={`${values.cdnPrefix}/images/content28/2_3.gif`}
              alt=""
            />
          </S.GifWrapper3>
        </S.ImgGroup>
      </S.Content>
    </S.Section>
  );
}
