import styled from '@emotion/styled';

export const StyledMainContainer = styled.main`
  font-size: 16px;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  min-height: 10px;
`;
