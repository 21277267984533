import styled from '@emotion/styled';

const Section = styled.section<{ bgColor?: string }>`
  position: relative;
  width: 100%;
  min-height: 300px;
  height: auto;
  overflow: hidden;
  background-color: ${(props) => props.bgColor || '#FFFFFF'};
`;

const Background = styled.div`
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  img {
    max-width: 1260px;
    width: 100%;
  }
`;

const GifWrapper = styled.div`
  position: absolute;
  top: 17%;
  left: 50%;
  width: 600px;
  height: 360px;
  display: flex;
  justify-content: center;
  transform: translate(-50%, 0);

  img {
    padding: 0;
    border-radius: 30px;
    border: 7px solid #e8e8e8;
    box-shadow: 0 4px 0 #d9d9d9;
    background: #e8e8e8 0 0 no-repeat padding-box;
    display: block;
    width: 600px;
    height: auto;
  }
`;

const StyledMainContent6 = { Section, Background, GifWrapper, Content };

export default StyledMainContent6;
