'use client';

import React, { useRef, useState } from 'react';
import type { SwiperRef } from 'swiper/swiper-react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { values } from '@/features/main/configs';
import { default as S } from './MainContent17.styled';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

interface TProps {
  bgColor?: string;
}

export default function MainContent17({ bgColor }: TProps) {
  const nextBtnRef = useRef<HTMLButtonElement>(null);
  const prevBtnRef = useRef<HTMLButtonElement>(null);
  const swiperRef = useRef<SwiperRef>(null);
  const [swiperIndex, setSwiperIndex] = useState(0);

  const items = [
    { label: '개념 이해 애니메이션', imgName: '2_1' },
    { label: '익히기 반복 학습', imgName: '2_2' },
    { label: '틀린 문제 설명 영상', imgName: '2_3' },
    { label: '개념 이해 강의', imgName: '2_4' },
    { label: '영어 따라 말하기', imgName: '2_5' },
    { label: '영어 책 읽기 연습', imgName: '2_6' },
  ];

  const slideTo = (idx: number) => {
    swiperRef.current?.swiper.slideToLoop(idx);
  };
  return (
    <S.Section id="main-content-17" bgColor={bgColor}>
      <S.Background>
        <img
          loading="lazy"
          src={`${values.cdnPrefix}/images/content17/1.png`}
          alt="시청각 학습과 함께 연필을 잡고 우리아이 두뇌 발달까지 극대화해주세요"
        />
      </S.Background>
      <S.SwiperContainer>
        <S.SwiperButtonGroup>
          <div>
            {items.slice(0, 3).map((v, idx) => {
              const active = swiperIndex === idx;
              return (
                <button
                  key={v.label}
                  className={active ? 'active' : ''}
                  onClick={() => slideTo(idx)}
                >
                  {v.label}
                </button>
              );
            })}
          </div>
          <div>
            {items.slice(3).map((v, idx) => {
              const active = swiperIndex === idx + 3;
              return (
                <button
                  key={v.label}
                  className={active ? 'active' : ''}
                  onClick={() => slideTo(idx + 3)}
                >
                  {v.label}
                </button>
              );
            })}
          </div>
        </S.SwiperButtonGroup>
        <Swiper
          ref={swiperRef}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          loop={true}
          modules={[Autoplay, Navigation, Pagination]}
          navigation={{
            enabled: true,
            nextEl: nextBtnRef.current,
            prevEl: prevBtnRef.current,
          }}
          onSwiper={(swiper) => {
            setTimeout(() => {
              // @ts-ignore
              swiper.params.navigation.prevEl = prevBtnRef.current;
              // @ts-ignore
              swiper.params.navigation.nextEl = nextBtnRef.current;
              swiper.navigation.destroy();
              swiper.navigation.init();
              swiper.navigation.update();
            });
          }}
          spaceBetween={70}
          slidesPerView={3}
          centeredSlides
          pagination={{
            clickable: true,
          }}
          onSlideChange={(swiper) => setSwiperIndex(swiper.realIndex)}
        >
          {items.map((v) => (
            <SwiperSlide key={v.imgName}>
              <S.SlideImageWrapper>
                <img
                  src={`${values.cdnPrefix}/images/content17/${v.imgName}.gif`}
                  alt=""
                />
              </S.SlideImageWrapper>
            </SwiperSlide>
          ))}
        </Swiper>
        <S.PrevButton ref={prevBtnRef}>
          <img
            src={`${values.cdnPrefix}/images/content17/prev-button.png`}
            alt="prev"
          />
        </S.PrevButton>
        <S.NextButton ref={nextBtnRef}>
          <img
            src={`${values.cdnPrefix}/images/content17/next-button.png`}
            alt="next"
          />
        </S.NextButton>
      </S.SwiperContainer>
    </S.Section>
  );
}
