import React from 'react';
import { default as S } from './MainContent0.styled';
import values from '../../configs/values';

interface TProps {
  bgColor?: string;
}

export default function MainContent0({ bgColor }: TProps) {
  return (
    <S.Section id="main-content-0" bgColor={bgColor}>
      <S.VideoContainer>
        <video
          src={`${values.cdnPrefix}/video/content0/1.mp4`}
          autoPlay
          muted
          loop
          playsInline
        />
      </S.VideoContainer>
      <S.BackgroundLayer />
      <S.TextLayer>가장 많이 선택하는 1위 학습 윙크</S.TextLayer>
    </S.Section>
  );
}
