import { default as S } from './MainContent15.styled';
import { values } from '@/features/main/configs';

interface TProps {
  bgColor?: string;
}

export default function MainContent15({ bgColor }: TProps) {
  return (
    <S.Section id="main-content-15" bgColor={bgColor}>
      <S.Background
        loading="lazy"
        src={`${values.cdnPrefix}/images/content15/1.png`}
        alt="시청각 학습과 함께 연필을 잡고 우리아이 두뇌 발달까지 극대화해주세요"
      />
      <S.Gif
        loading="lazy"
        src={`${values.cdnPrefix}/images/content15/2.gif`}
        alt="자녀 수준 막춤 패키지 구성"
      />
    </S.Section>
  );
}
