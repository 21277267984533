import React from 'react';
import dynamic from 'next/dynamic';
import { StyledMainContainer } from './MainPage.styled';
import MainContent from '@/features/main/MainContent/MainContent';

const EventPopUp = dynamic(() => import('@/features/common/EventPopUp'), {
  ssr: false,
});

const MainPage = ({ history }: { history: any }) => {
  return (
    <StyledMainContainer>
      <MainContent />
      <EventPopUp />
    </StyledMainContainer>
  );
};

export default MainPage;
