import { default as S } from './MainContent6.styled';
import { values } from '@/features/main/configs';

interface TProps {
  bgColor?: string;
}

export default function MainContent6({ bgColor }: TProps) {
  return (
    <S.Section id="main-content-6" bgColor={bgColor}>
      <S.Background>
        <img
          loading="lazy"
          src={`${values.cdnPrefix}/images/content6/1.png`}
          alt="배경"
        />
      </S.Background>
      <S.Content>
        <img
          loading="lazy"
          src={`${values.cdnPrefix}/images/content6/2.png`}
          alt="현직 초등교사들도 추천하는 윙크"
        />
      </S.Content>
      <S.GifWrapper>
        <img
          loading="lazy"
          src={`${values.cdnPrefix}/images/content6/3.gif`}
          alt="옥스퍼드 조지은 교수"
        />
      </S.GifWrapper>
    </S.Section>
  );
}
