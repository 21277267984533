import styled from '@emotion/styled';

const Section = styled.section<{ bgColor?: string }>`
  position: relative;
  width: 100%;
  min-height: 300px;
  height: auto;
  overflow: hidden;
  background-color: ${(props) => props.bgColor || '#FFFFFF'};
  display: flex;
  justify-content: center;
`;

const Background = styled.div`
  display: flex;
  justify-content: center;
  img {
    max-width: 1260px;
    width: 100%;
  }
`;

const Content = styled.div`
  position: absolute;
  top: 74%;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ButtonGroup = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  img {
    display: block;
    width: 65%;
  }
  button {
    position: absolute;
    top: 35%;
    left: 50%;
    width: 56%;
    height: 70%;
    cursor: pointer;
    transform: translate(-50%, 0);
  }
`;

const StyledMainContent3 = { Section, Background, Content, ButtonGroup };

export default StyledMainContent3;
