import styled from '@emotion/styled';

const Section = styled.section<{ bgColor?: string }>`
  position: relative;
  width: 100%;
  min-height: 300px;
  height: auto;
  overflow: hidden;
  background-color: ${(props) => props.bgColor || '#FFFFFF'};
`;

const Background = styled.div`
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  top: 0;

  img {
    height: 100%;
  }
`;

const StyledMainContent2 = {
  Section,
  Background,
  Content,
};

export default StyledMainContent2;
