import { useRouter } from 'next/router';
import { default as S } from './MainContent3.styled';
import { values } from '@/features/main/configs';

interface TProps {
  bgColor?: string;
}

export default function MainContent3({ bgColor }: TProps) {
  const router = useRouter();
  return (
    <S.Section id="main-content-3" bgColor={bgColor}>
      <S.Background>
        <img
          loading="lazy"
          src={`${values.cdnPrefix}/images/content3/1.png`}
          alt="한눈에 보는 무료체험 과정"
        />
      </S.Background>
      <S.Content>
        <S.ButtonGroup>
          <img
            loading="lazy"
            src={`${values.cdnPrefix}/images/content3/2.png`}
            alt="부담없는 무료체험 신청"
          />
          <button onClick={() => router.push('/wink/free-study')} />
        </S.ButtonGroup>
      </S.Content>
    </S.Section>
  );
}
