import styled from '@emotion/styled';

const Section = styled.section<{ bgColor?: string }>`
  position: relative;
  width: 100%;
  min-height: 300px;
  height: auto;
  overflow: hidden;
  background-color: ${(props) => props.bgColor || '#FFFFFF'};
`;

const Background = styled.div`
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  img {
    max-width: 1260px;
    width: 100%;
  }
`;

const Footer = styled.div`
  position: absolute;
  bottom: 5%;
  width: 100%;
  height: 9%;
  display: flex;
  justify-content: center;
  img {
    display: block;
  }
  button {
    position: absolute;
    top: 38%;
    left: 39%;
    width: 22%;
    height: 62%;
    cursor: pointer;
  }
`;

const StyledMainContent35 = { Section, Background, Content, Footer };

export default StyledMainContent35;
