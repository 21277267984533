import { default as S } from './MainContent4.styled';
import { values } from '@/features/main/configs';

interface TProps {
  bgColor?: string;
}

export default function MainContent4({ bgColor }: TProps) {
  return (
    <S.Section id="main-content-4" bgColor={bgColor}>
      <S.Background>
        <img
          loading="lazy"
          src={`${values.cdnPrefix}/images/content4/1.png`}
          alt="배경"
        />
      </S.Background>
      <S.Content>
        <img
          loading="lazy"
          src={`${values.cdnPrefix}/images/content4/2.png`}
          alt="윙크는 미래를 살아갈 우리아이에게 꼭 필요한 온라인과 오프라인의 장점만을 결합한 블렌디드 러닝입니다."
        />
      </S.Content>
    </S.Section>
  );
}
