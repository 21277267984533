'use client';

import { useRouter } from 'next/router';
import { default as S } from './MainContent35.styled';
import { values } from '@/features/main/configs';

interface TProps {
  bgColor?: string;
}

export default function MainContent35({ bgColor }: TProps) {
  const router = useRouter();
  return (
    <S.Section id="main-content-35" bgColor={bgColor}>
      <S.Background>
        <img
          loading="lazy"
          src={`${values.cdnPrefix}/images/content35/1.png`}
          alt="배경"
        />
      </S.Background>
      <S.Content>
        <img
          loading="lazy"
          src={`${values.cdnPrefix}/images/content35/2.png`}
          alt="배경"
        />
      </S.Content>
      <S.Footer>
        <img
          loading="lazy"
          src={`${values.cdnPrefix}/images/content35/3.png`}
          alt="배경"
        />
        <button onClick={() => router.push('/wink/free-study')} />
      </S.Footer>
    </S.Section>
  );
}
