import styled from '@emotion/styled';

const Section = styled.section<{ bgColor?: string }>`
  width: 100%;
  height: auto;
  min-height: 170px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  background-color: ${(props) => props.bgColor || '#FFFFFF'};
  img {
    max-width: 1260px;
    width: 100%;
  }
`;

const StyledSimpleMainContent = { Section };

export default StyledSimpleMainContent;
