import styled from '@emotion/styled';

const Section = styled.section<{ bgColor?: string }>`
  position: relative;
  width: 100%;
  min-height: 300px;
  height: auto;
  display: flex;
  justify-content: center;
  overflow: hidden;
  background-color: ${(props) => props.bgColor || '#FFFFFF'};
`;

const Background = styled.div`
  display: flex;
  justify-items: center;
  max-width: 1400px;
  img {
    display: block;
    max-width: 1260px;
    width: 100%;
  }
`;

const SwiperContainer = styled.div`
  position: absolute;
  top: 11%;
  height: 29%;
  width: 1400px;

  .swiper {
    height: 65%;
    .swiper-slide {
      opacity: 0.4;
    }
    .swiper-slide-active {
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      background: #15b73a;
    }
  }
`;

const SwiperButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 98px;
  gap: 13px;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 13px;
    max-width: 1260px;
    width: 100%;

    button {
      font-family: Pretendard, sans-serif;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      cursor: pointer;
      width: fit-content;
      background: rgb(221, 221, 221);
      border-radius: 30px;
      padding: 12px 18px;
      color: white;
      font-size: 19px;
      font-weight: 600;
      min-width: 130px;
      &.active {
        background-color: #15b73a;
      }
    }
  }
`;

const SlideImageWrapper = styled.div`
  height: 77%;
  overflow: hidden;
  padding: 0.3rem;
  background-color: #ffffff;
  margin-left: -12px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ArrowButton = styled.button`
  position: absolute;
  top: 63%;
  width: 42px;
  height: 42px;
  z-index: 1;
  cursor: pointer;
  img {
    display: block;
    width: 100%;
  }
`;

const PrevButton = styled(ArrowButton)`
  left: 30.8%;
`;
const NextButton = styled(ArrowButton)`
  right: 31.2%;
`;

const MainContent17 = {
  Section,
  Background,
  SwiperContainer,
  SwiperButtonGroup,
  SlideImageWrapper,
  PrevButton,
  NextButton,
};

export default MainContent17;
