import styled from '@emotion/styled';

const Section = styled.section<{ bgColor?: string }>`
  position: relative;
  min-height: 300px;
  height: auto;
  overflow: hidden;
  background-color: ${(props) => props.bgColor || '#FFFFFF'};
`;

const Background = styled.div`
  display: flex;
  justify-content: center;
  img {
    max-width: 1260px;
    width: 100%;
  }
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  max-width: 1260px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const ImgGroup = styled.div`
  position: absolute;
  top: 42%;
  right: 25.3%;
  width: 30%;
  height: 35%;
  overflow: hidden;
  border-radius: 1rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Img = styled.img`
  position: absolute;
  top: 55.7%;
  right: 20.8%;
  width: 14%;
  display: block;
`;

const StyledMainContent32 = { Section, Background, Content, ImgGroup, Img };

export default StyledMainContent32;
