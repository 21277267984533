import styled from '@emotion/styled';

const Section = styled.section<{ bgColor?: string }>`
  position: relative;
  width: 100%;
  min-height: 300px;
  height: 800px;
  overflow: hidden;
  background-color: ${(props) => props.bgColor || '#FFFFFF'};
`;

const VideoContainer = styled.div`
  @font-face {
    font-family: 'SBAggro';
    src: url('https://us.wink.co.kr/resources/ltp/fonts/common/SBAggro/SBAggroB.woff')
      format('woff');
    font-weight: normal;
    font-style: normal;
  }

  width: 100%;
  height: 100%;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Layer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const BackgroundLayer = styled(Layer)`
  background-color: rgba(0, 0, 0, 0.4);
`;

const TextLayer = styled(Layer)`
  font-family: 'SBAggro', 'Noto Sans KR', sans-serif;
  color: white;
  font-size: 3.75rem;
  display: flex;
  justify-content: center;
  margin-top: 6rem;
`;

const StyledMainContent0 = {
  Section,
  VideoContainer,
  BackgroundLayer,
  TextLayer,
};

export default StyledMainContent0;
