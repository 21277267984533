import { default as S } from './MainContent32.styled';
import { values } from '@/features/main/configs';

interface TProps {
  bgColor?: string;
}

export default function MainContent32({ bgColor }: TProps) {
  return (
    <S.Section id="main-content-32" bgColor={bgColor}>
      <S.Background>
        <img
          loading="lazy"
          src={`${values.cdnPrefix}/images/content32/1.png`}
          alt="아이에게 학습 동기 부여는 매우 중요"
        />
      </S.Background>
      <S.Content>
        <S.ImgGroup>
          <img
            loading="lazy"
            src={`${values.cdnPrefix}/images/content32/2.gif`}
            alt="동기부여"
          />
        </S.ImgGroup>
        <S.Img
          loading="lazy"
          src={`${values.cdnPrefix}/images/content32/3.png`}
          alt="동기부여"
        />
      </S.Content>
    </S.Section>
  );
}
