import styled from '@emotion/styled';

const Section = styled.section<{ bgColor?: string }>`
  position: relative;
  min-height: 300px;
  height: auto;
  overflow: hidden;
  background-color: ${(props) => props.bgColor || '#FFFFFF'};
`;

const Background = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const videoWrapper = styled.div`
  position: absolute;
  max-width: 1280px;
  width: 100%;
  height: 100%;
  video {
    position: absolute;
    top: 68.4%;
    left: 50%;
    width: 50%;
    transform: translate(-50%, 0);
    border-radius: 1rem;
  }
`;

const StyledMainContent23 = { Section, Background, Content, videoWrapper };

export default StyledMainContent23;
