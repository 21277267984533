import { values } from '../../configs';
import { default as S } from './MainContent1.styled';
import { MouseEvent, useEffect, useRef, useState } from 'react';

interface TProps {
  bgColor?: string;
}

export default function MainContent1({ bgColor }: TProps) {
  const imageWidth = 193;
  const imageLength = 10;
  const cloneImageSet = 3;
  const imageGap = 20;
  const imageOneSetWidth = (imageWidth + imageGap) * imageLength;
  const sliderWidth = imageOneSetWidth * cloneImageSet;

  const [position, setPosition] = useState(-imageOneSetWidth);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [initialPos, setInitialPos] = useState(0);
  const sliderRef = useRef(null);
  const animationRef = useRef<number | null>(null);

  useEffect(() => {
    if (!isDragging) {
      animationRef.current = requestAnimationFrame(moveSlider);
    } else {
      if (animationRef.current !== null) {
        cancelAnimationFrame(animationRef.current);
      }
    }
    return () => {
      if (animationRef.current !== null) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [isDragging, position]);

  useEffect(() => {
    if (
      // 두번째 이미지 셋까지 가면, 첫번째 이미지 셋으로 이동
      position <= imageOneSetWidth * -2 ||
      // 맨 처음으로 가면, 첫번째 이미지 셋으로 이동
      position >= 0
    ) {
      setPosition(-imageOneSetWidth);
    }
  }, [position, sliderWidth]);

  const moveSlider = () => {
    setPosition((prevPosition) => {
      return prevPosition - 1;
    });
    animationRef.current = requestAnimationFrame(moveSlider);
  };

  const onMouseDown = (e: MouseEvent) => {
    setIsDragging(true);
    setStartX(e.clientX);
    setInitialPos(position);
  };

  const onMouseMove = (e: MouseEvent) => {
    if (isDragging) {
      const dx = e.clientX - startX;
      setPosition(initialPos + dx);
    }
  };

  const onMouseUp = () => {
    setIsDragging(false);
  };

  const onMouseLeave = () => {
    if (isDragging) {
      setIsDragging(false);
    }
  };

  return (
    <S.Section id="main-content-1" bgColor={bgColor}>
      <S.Background>
        <img
          loading="lazy"
          src={`${values.cdnPrefix}/images/content1/1.png`}
          alt="왜 학부모님들은 윙크를 압도적으로 많이 선택할까요?"
        />
      </S.Background>
      <S.Content>
        <S.ReviewContainer
          isDragging={isDragging}
          onMouseDown={onMouseDown}
          onMouseMove={onMouseMove}
          onMouseUp={onMouseUp}
          onMouseLeave={onMouseLeave}
        >
          <S.ReviewRow>
            <S.ReviewItemGroup
              ref={sliderRef}
              imageGap={imageGap}
              position={position}
              isDragging={isDragging}
            >
              {Array.from(
                { length: imageLength * cloneImageSet },
                (_, idx) => (idx % imageLength) + 1
              ).map((name, idx) => (
                <S.ReviewItem
                  key={`slide-${idx}`}
                  loading="lazy"
                  imageWidth={imageWidth}
                  src={`${values.cdnPrefix}/images/content1/2_${name}.png`}
                />
              ))}
            </S.ReviewItemGroup>
            <S.ReviewLayer />
          </S.ReviewRow>
        </S.ReviewContainer>
      </S.Content>
    </S.Section>
  );
}
