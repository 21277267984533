import styled from '@emotion/styled';

const Section = styled.section<{ bgColor?: string }>`
  position: relative;
  width: 100%;
  min-height: 300px;
  height: auto;
  background-color: ${(props) => props.bgColor || '#FFFFFF'};
`;

const Background = styled.div`
  display: flex;
  justify-content: center;
  img {
    max-width: 1260px;
    width: 100%;
    display: inline-block;
  }
`;

const Content = styled.div`
  position: absolute;
  top: 30%;
  left: 0;
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ReviewContainer = styled.div<{ isDragging: boolean }>`
  overflow: hidden;
  width: 100%;
  max-width: 1340px;
  user-select: none;
  cursor: ${(props) => (props.isDragging ? 'grabbing' : 'grab')};
`;

const ReviewLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 1340px;
  height: 100%;
  background: linear-gradient(
    to right,
    white 3%,
    transparent 20%,
    transparent 80%,
    white 97%
  );
`;

const ReviewRow = styled.div`
  position: relative;
  display: flex;
  height: 100%;
`;

const ReviewItemGroup = styled.div<{
  position: number;
  isDragging: boolean;
  imageGap: number;
}>`
  display: flex;
  flex-wrap: nowrap;
  gap: ${(props) => props.imageGap}px;
  padding: 0 ${(props) => props.imageGap / 2}px;
  height: 100%;
  box-sizing: border-box;
  transform: translateX(${(props) => props.position}px);
`;

const ReviewItem = styled.img<{ imageWidth: number }>`
  display: block;
  width: ${(props) => props.imageWidth}px;
`;

const StyledMainContent1 = {
  Section,
  Background,
  Content,
  ReviewContainer,
  ReviewLayer,
  ReviewRow,
  ReviewItemGroup,
  ReviewItem,
};

export default StyledMainContent1;
